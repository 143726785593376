@import "_fonts.scss";

$font_RUBSN: RUBSN;

// primary
:root {
    --color-red-900: #AF1B3F;
    --color-black-900: #000000; 
    --color-black-800: #333; 
    --color-gray-800: #CACACA; 
    --color-gray-500: #B9B9B9; 
    --color-gray-400: #D2D2D2; 
    --color-gray-300: #FAF6F5; 
    --color-gray-200: #F2F2F0; 
    --color-gray-150: #E7E7E6; 
    --color-gray-100: #EFF1F5; 
    --color-gray-50: #F9F9FB; 
    --color-gray-10: #E2DEDE; 
    --color-gray-11: #D9D9D9; 
}

:root{
    --indent-1: 10px;
    --indent-1a: 12px;
    --indent-1b: 20px;
    --indent-2: 25px;
    --indent-3: 30px;
    --indent-4: 35px;
    --indent-4b: 50px;
    --indent-5: 60px;

    --font-family: Mulish_Regular;
    --font-family-bold: Mulish_Bold;
    --font-family-light: Mulish_Light;

    --font-size: 16px;
    --font-size-xs: 10px;
    --font-size-sm: 12px;
    --font-size-md: 14px;
    --font-size-lg: 16px;       // h4
    --font-size-lgl: 18px;      
    --font-size-xl: 20px;       
    --font-size-2xl: 24px;      // h3
    --font-size-3xl: 30px;      
    --font-size-3xl-che: 30px;      
    --font-size-4xl: 35px;      // h2
    --font-size-5xl: 40px;      
    --font-size-6xl: 45px;      // h1

    --line-height-1: normal;

    --color: var(--color-black-900);                // #000000
    --color-normal: var(--color-black-900);         // #000000
    --color-white: #ffffff;                         // #ffffff
    --color-gray: var(--color-gray-800);            // #CACACA
    --color-gray-light: var(--color-gray-500);      // #B9B9B9
    --color-gray-extra: var(--color-gray-300);      // #D2D2D2
    --color-link: var(--color-normal);          
    --color-active: var(--color-gray-500);          
    --color-hover: var(--color-gray-500);           
    --color-primary: var(--color-gray-800);         
    --color-bg-strong: var(--color-black-800);       
    --color-bg-img: var(--color-gray-400);          
    --color-error: var(--color-red-900);            // #AF1B3F

    --rgba-01: rgba(0,0,0,0.3);
    --rgba-02: rgba(255,255,255,0.4);
    --rgba-03: rgba(255,255,255,0.3);

}
