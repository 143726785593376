// @font-face{
//     font-family: Monserat_Regular;
//     display: swap;
//     font-display: swap;
//     src: url("../fonts/Montserrat-Regular.ttf") format("opentype");
// }
// @font-face{
//     font-family: Monserat_Bold;
//     display: swap;
//     font-display: swap;
//     src: url("../fonts/Montserrat-Bold.ttf") format("opentype");
// }
// @font-face{
//     font-family: Monserat_Light;
//     display: swap;
//     font-display: swap;
//     src: url("../fonts/Montserrat-Light.ttf") format("opentype");
// }

@font-face{
    font-family: Mulish_Regular;
    display: swap;
    font-display: swap;
    src: url("../fonts/Mulish-Regular.ttf") format("opentype");
}
@font-face{
    font-family: Mulish_Bold;
    display: swap;
    font-display: swap;
    src: url("../fonts/Mulish-Bold.ttf") format("opentype");
}
@font-face{
    font-family: Mulish_Light;
    display: swap;
    font-display: swap;
    src: url("../fonts/Mulish-Light.ttf") format("opentype");
}

/*
@font-face{
    font-family: PTRootUI_Regular;
    display: swap;
    font-display: swap;
    src: url("../fonts/PT_Root_UI_Regular.woff") format("woff"),
        url("../fonts/PT_Root_UI_Regular.woff2") format("woff2"),
        url("../fonts/PT_Root_UI_Regular.eot"),
        url("../fonts/PT_Root_UI_Regular.ttf") format("opentype");
}
*/

@font-face{
    font-family: RUBSN;
    display: swap;
    src: url("../fonts/RUBSN.otf");
}
















